<template>
  <div>
    <template v-if="colorSettingsLoaded">
      <!-- <SplashScreenLoader /> -->
      <div id="app" class="overflow-x-hidden">
        <vue-extend-layouts />
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VueExtendLayouts from "vue-extend-layout";
// import SplashScreenLoader from "@/loaders/SplashScreenLoader.vue";

export default {
  name: "App",
  components: { VueExtendLayouts },
  metaInfo() {
    if (this.$route.path === "/") {
      return {
        title: "Mentorice - Break the ice, meet your Mentor!"
      };
    }
    return {
      title:
        "Mentorice" +
        this.toTitleCase(this.$route.path.replace("/", "").replace("-", " "))
    };
  },
  computed: {
    ...mapGetters({
      colorSettingsLoaded: "helper/getColorSettingsLoaded"
    })
  },
  mounted() {
    console.log("Welcome to mentorice.");
    this.$store.dispatch("helper/GET_COLOR_SETTINGS");
    window.addEventListener("alertify", this.onAlertify);
    //is production
    if (process.env.VUE_APP_ENABLED_GOOGLE_TAG == "true") {
      this.mountGTag();
    }
  },
  beforeDestroy() {
    window.removeEventListener("alertify", this.onAlertify);
  },
  methods: {
    mountGTag() {
      const script = document.createElement("script");
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-3T83XJGY89";
      script.addEventListener("load", this.onGtagLoad);
      document.body.appendChild(script);
    },
    onGtagLoad() {
      console.log("gtagLoaded");
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        // eslint-disable-next-line no-undef,prefer-rest-params
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "G-3T83XJGY89");
    },
    onAlertify({ detail: data }) {
      if (data.type == "error") {
        this.$alertify.error(data.message);
      } else {
        this.$alertify.success(data.message);
      }
    },
    toTitleCase(str) {
      return (
        " - " +
        str.replace(/\w\S*/g, function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        })
      );
    }
  }
};
</script>
<style lang="scss">
@import "assets/styles/main.scss";

body {
  margin: 0;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.container {
  @media (min-width: 1200px) {
    max-width: 930px;
  }
}
</style>
